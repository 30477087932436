<template>
  <nav aria-label="Page navigation" v-if="totalPages>1">
    <ul class="pagination float-md-right justify-content-center-md mb-0">
      <li class="pagination-prev page-item">
        <button type="button" :class="{'page-link':true, 'deshabilitado':current_page<=1}" :disabled="current_page<=1" v-on:click="changePage(current_page-1)">
          <img src="@/assets/image/previous_item.webp" style="height: 17px;">
        </button>
      </li>
      <li class="pagination-first page-item">
        <button type="button" :class="{'page-link':true, 'deshabilitado':current_page<=1}" :disabled="current_page<=1" v-on:click="changePage(1)">
          <img src="@/assets/image/first_item.webp" style="height: 17px;">
        </button>
      </li>
      <li v-if="false">
        <select v-on:change="changePage(page+1)" class="custom-select">
          <option v-for="(page, index) in pagesNumber" :key="index" :value="page">
            {{page}}
          </option>
        </select>
      </li>
      <li class="pagination-page page-item" v-for="(page, index) in pagesNumber" :class="{'active': page+1 == current_page}" :key="index" v-else>
        <button type="button" class="page-link" v-on:click="changePage(page+1)">
          <span v-if="pagesNumber[0] > 0 && index == 0">
            ...
          </span>
          {{page+1}}
          <span v-if="(pagesNumber[(limit*2)]) < (totalPages-1) && index == (pagesNumber.length-1)">
            ...
          </span>
        </button>
      </li>
      <li class="pagination-last page-item">
        <button type="button" :class="{'page-link':true, 'deshabilitado':current_page>=totalPages}" :disabled="current_page>=totalPages" v-on:click="changePage(totalPages)">
          <img src="@/assets/image/last_item.webp" style="height: 17px;">
        </button>
      </li>
      <li class="pagination-next page-item">
        <button type="button" :class="{'page-link':true, 'deshabilitado':current_page>=totalPages}" :disabled="current_page>=totalPages" v-on:click="changePage(current_page+1)">
          <img src="@/assets/image/next_item.webp" style="height: 17px;">
        </button>
      </li>
    </ul>
  </nav>
</template>
<script>
  export default{
    data(){
      return {
        current_page: 1
      }
    },

    emits: ['paginate'],

    props: {
      pagination: {
        type: Object,
        required: true
      },
      offset: {
        type: Number,
        default: 4
      }
    },
    computed: {
      isMobile() {
        return this.$store.getters.isMobile
      },
      totalPages(){
        var pages = (this.paginationDF.total || 0)/(this.paginationDF.per_page || 1)
        if(pages>parseInt(pages)){pages=parseInt(pages)+1}
        return pages
      },
      limit(){
        var count = 3
        if (this.isMobile) {
          count = 1
        }
        return count
      },
      pagesNumber() {
        var ini = this.current_page - this.limit;

        if(ini<=0){
          ini = 1
        }
        
        if(ini >= (this.totalPages-(this.limit*2))){
          ini = this.totalPages - (this.limit*2)
        }

        if(ini<=0){
          ini = 1
        }
        var fin = ini + (this.limit*2)
        
        if (this.paginationDF.total>0) {
          return Array.from(Array(this.totalPages).keys()).slice((ini-1), fin)
        }
        return []
      },
      paginationDF(){
        return this.pagination || {}
      }
    },
    methods : {
      changePage(page) {
        this.current_page = page
        this.$emit('paginate', page)
      },
    },

    watch: {
      'pagination': {
        handler(val){
          this.current_page = val.current_page || 1
          return val
        },
        deep: true
      }
    }
  }
</script>